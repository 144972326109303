import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const refreshAuthLogic = failedRequest => axios.post('auth/login/refresh',{}).then(tokenRefreshResponse => {
  localStorage.setItem('token', tokenRefreshResponse.data.access_token);
  failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.access_token;
  return Promise.resolve();
});

/*
    This function sets a custom url in the app
    baseUrl:string
 */
function configAxios(baseUrl) {

  axios.defaults.withCredentials = true
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  createAuthRefreshInterceptor(axios, refreshAuthLogic);
}
export default configAxios;
