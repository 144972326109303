import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { differenceInCalendarYears, parse } from "date-fns";
import {
  getAllRiskProfiles,
  getAllRiskProfilesWithoutTermAndProtection,
  getRiskProfile, getRiskProfilePerformance,
  postRiskProfile
} from "../../services/riskProfile/riskProfile";
import {postFinanbestRiskProfile} from "../../services/indexaCapital/finanbest/riskProfile/finanbestRiskProfile";

const initialState = {
  error:false,
  questionIndex: 0,
  type: null,
  investment: null,
  answers: {
    goal: null,
    attitude: null,
    risk: null,
    experience: null,
    age: null,
    wealth: null,
    income: null,
    stability: null,
    expenses: null,
    horizon: null,
  },
  answers_finanbest: {
    income:null,
    expenses:null,
    stability:null,
    wealth:null,
    investment:null,
    years_investing:null,
    studies:null,
    experience:null,
    age:null,
    goal:null,
    horizon:null,
    risk:null,
    attitude:null
  },
  data: {
    profile:null,
    maxProfile:null,
    max_performance:0,
    min_performance:0,
    expected_performance:0
  },
  riskProfiles:[],
  isTestDialogVisible:false,
  isRiskProfileDrawerVisible:false
};

export const selectNewRiskProfile=createAsyncThunk(
    "risk_profile/selectNewRiskProfile",
    async(data)=>{
      window.dataLayer.push({
        'event':'setRiskProfile',
        'risk_profile':data
      })
      return postRiskProfile(data).then(response=>response.data)
    }
)

export const getRiskProfileData=createAsyncThunk(
    "risk_profile/getRiskProfileData",
    async()=>{
    return getRiskProfile().then(response=>response.data)
    }
)

export const getRiskProfiles = createAsyncThunk(
    "risk_profile/getRiskProfiles",
    /*
          The parameters of the funcion are (data, thunkAPI)
          async (userData,thunkAPI)
       */
    async (firstObjective, thunkAPI) => {
      if (firstObjective) {
        let term
        if (firstObjective.data.protection_type === "SHORT_TERM") {
          term = firstObjective.data.term + firstObjective.data.duration / 12
        } else {
          term = firstObjective.data.term
        }
        return getAllRiskProfiles(
            {
              term: term,
              protection_type: firstObjective.data.protection_type
            }).then(response => {
          return response.data
        });
      } else {
        return getAllRiskProfilesWithoutTermAndProtection().then(response => {
          return response.data
        })
      }
    }
);

export const getPerformanceActiveRiskProfile = createAsyncThunk(
    "risk_profile/getPerformanceActiveRiskProfiles",
    /*
          The parameters of the funcion are (data, thunkAPI)
          async (userData,thunkAPI)
       */
    async (objectiveData, thunkAPI) => {
      return getRiskProfilePerformance(objectiveData).then(response => {
        return response.data
      })
    }

);

export const sendRiskProfileForm = createAsyncThunk(
  "risk_profile/sendRiskProfileForm",
  /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
  async (data, thunkAPI) => {
    const { riskProfile } = data;
    const riskProfileParsed = {
      type: riskProfile.type,
      investment: riskProfile.investment,
      answers: {
        goal: riskProfile.answers.goal,
        attitude: riskProfile.answers.attitude,
        risk: riskProfile.answers.risk,
        experience: riskProfile.answers.experience,
        age: riskProfile.answers.age,
        wealth: riskProfile.answers.wealth,
        income: riskProfile.answers.income,
        stability: riskProfile.answers.stability,
        expenses: riskProfile.answers.expenses,
        horizon: riskProfile.answers.horizon,
      },
    };
    const responseRiskProfile = postRiskProfile(riskProfileParsed);
    return Promise.all([responseRiskProfile]).then((responsesData) => {
      /*
                we return a number of objects equal to the number of petitions with the name of the url we are calling
                because we name in the initial state our parameters as the names on the endpoints
             */
      return responsesData.reduce((obj, responseData) => {
        obj[responseData.url.replace(/-/g, "_")] = responseData.data;
        return obj;
      }, {});
    });
  }
);

export const sendRiskProfileFormFinanbest = createAsyncThunk(
    "risk_profile/sendRiskProfileFormFinanbest",
    /*
          The parameters of the funcion are (data, thunkAPI)
          async (userData,thunkAPI)
       */
    async (data, thunkAPI) => {
      const {first_objective,plan}=thunkAPI.getState()
      const initialInvestment=first_objective.contribution?first_objective.contribution:plan.data.global_info.initial_investment

      const { riskProfile } = data;
      const percentageInvestment=initialInvestment/riskProfile.answers_finanbest.wealth
      const riskProfileParsed = {
        answers: {
          income:parseInt(riskProfile.answers_finanbest.income),
          expenses:riskProfile.answers_finanbest.expenses<=25?1:(riskProfile.answers_finanbest.expenses>25 && riskProfile.answers_finanbest.expenses<=75)?2:3,
          stability:parseInt(riskProfile.answers_finanbest.stability),
          wealth:riskProfile.answers_finanbest.wealth<25000?1:(riskProfile.answers_finanbest.wealth>=25000 && riskProfile.answers_finanbest.wealth<=50000)?2:(riskProfile.answers_finanbest.wealth>50000 && riskProfile.answers_finanbest.wealth<=100000)?3:4,
          investment:percentageInvestment<0.25?1:(percentageInvestment>=0.25 && percentageInvestment<=0.5)?2:(percentageInvestment>0.5 && percentageInvestment<=0.75)?3:4,
          years_investing:parseInt(riskProfile.answers_finanbest.years_investing),
          studies:parseInt(riskProfile.answers_finanbest.studies),
          experience:parseInt(riskProfile.answers_finanbest.experience),
          age:parseInt(riskProfile.answers_finanbest.age),
          goal:parseInt(riskProfile.answers_finanbest.goal),
          horizon:parseInt(riskProfile.answers_finanbest.horizon),
          risk:parseInt(riskProfile.answers_finanbest.risk),
          attitude:parseInt(riskProfile.answers_finanbest.attitude)
        }
        }
      const responseRiskProfile = postFinanbestRiskProfile(riskProfileParsed);
      return Promise.all([responseRiskProfile]).then((responsesData) => {
        /*
                  we return a number of objects equal to the number of petitions with the name of the url we are calling
                  because we name in the initial state our parameters as the names on the endpoints
               */
        return responsesData.reduce((obj, responseData) => {
          obj[responseData.url.replace(/-/g, "_")] = responseData.data;
          return obj;
        }, {});
      });
    }
);

const riskProfileSlice = createSlice({
  name: "risk_profile",
  initialState: initialState,
  reducers: {
    /*
        receive as payload {firstObjective:{...},user:{...}
         */
    addRiskProfile(state, action) {
      const { firstObjective, user } = action.payload;
      const expenses = (user.expenses.total / user.income.total) * 100;
      let horizon
      if(firstObjective.data.protection_type==="SHORT_TERM"){
        horizon = differenceInCalendarYears(
            parse(firstObjective.date_end, "dd/MM/yyyy", new Date()),
            new Date()
        );
      }else{
        horizon = differenceInCalendarYears(
            parse(firstObjective.date_start, "dd/MM/yyyy", new Date()),
            new Date()
        );
      }
      const riskProfile = {
        ...initialState,
        investment: firstObjective.contribution,
        riskProfiles:[...state.riskProfiles],
        answers: {
          ...state.answers,
          age: differenceInCalendarYears(
            new Date(),
            parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())
          ),
          income: user.income.total * 12,
          expenses:
            expenses <= 25
              ? 25
              : 25 < expenses && expenses <= 50
              ? 50
              : 50 < expenses && expenses <= 75
              ? 75
              : 100,
          horizon:
            horizon <= 1
              ? 1
              : 1 < horizon && horizon <= 2
              ? 2
              : 2 < horizon && horizon <= 5
              ? 5
              : 5 < horizon && horizon <= 10
              ? 10
              : 100,
        },
      };
      if (action.payload.type === "RETIREMENT") {
        return {...riskProfile, type: "pension" };
      } else {
        return {...riskProfile, type: "mutual" };
      }
    },
    addFinanbestRiskProfile(state,action){
      const {user,objectives}=action.payload
      //const expenses = (user.expenses.total / user.income.total) * 100;
      const yearlyIncome=user.income.total*12
      const income=yearlyIncome<=50000?
          1:
          (yearlyIncome>50000 && yearlyIncome<=300000)?
              2:
              (yearlyIncome>300000 && yearlyIncome<=600000)?
                  3:4
      const age = differenceInCalendarYears(
          new Date(),
          parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())
      )
      const answerAge=age<35?1:(age>=35 && age<55)?2:(age>=55&&age<=67)?3:4
      const longestTimeHorizonPlusDuration=objectives.reduce((longest,objective)=>{
        if(longest<(objective.time_horizon+(objective.duration?Math.round(parseInt(objective.duration)/12):0))){
          longest=objective.time_horizon+(objective.duration?Math.round(parseInt(objective.duration)/12):0)
        }
        return longest
      },0)
      //const q2 = expenses<25?1:expenses>75?3:2
      const horizon = longestTimeHorizonPlusDuration<3?1:(longestTimeHorizonPlusDuration>=3 && longestTimeHorizonPlusDuration<6)?2:(longestTimeHorizonPlusDuration>=6 && longestTimeHorizonPlusDuration<=10?3:4)
      const riskProfile = {
        ...initialState,
        riskProfiles:[...state.riskProfiles],
        answers_finanbest: {
          ...state.answers_finanbest,
          age: answerAge,
          income: income,
          horizon: horizon
        },
      };
      return {...riskProfile}
    },
    beginRiskProfileTest(state, action) {
      const {user,objectives}=action.payload
      //const expenses = (user.expenses.total / user.income.total) * 100;
      const age = differenceInCalendarYears(
          new Date(),
          parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())
      )
      const yearlyIncome=user.income.total*12
      const income=yearlyIncome<=50000?
          1:
          (yearlyIncome>50000 && yearlyIncome<=300000)?
              2:
              (yearlyIncome>300000 && yearlyIncome<=600000)?
                  3:4
      const answerAge=age<35?1:(age>=35 && age<55)?2:(age>=55&&age<=67)?3:4
      const longestTimeHorizonPlusDuration=objectives.reduce((longest,objective)=>{
        if(longest<(objective.time_horizon+(objective.duration?Math.round(parseInt(objective.duration)/12):0))){
          longest=objective.time_horizon+(objective.duration?Math.round(parseInt(objective.duration)/12):0)
        }
        return longest
      },0)
      //const q2 = expenses<25?1:expenses>75?3:2
      const horizon = longestTimeHorizonPlusDuration<3?1:(longestTimeHorizonPlusDuration>=3 && longestTimeHorizonPlusDuration<6)?2:(longestTimeHorizonPlusDuration>=6 && longestTimeHorizonPlusDuration<=10?3:4)
      const riskProfile = {
        ...state,
        questionIndex:0,
        answers_finanbest: {
          ...initialState.answers_finanbest,
          expenses: null,
          age: answerAge,
          horizon:horizon,
          income:income
        },
      };
      return {...riskProfile}
    },
    addGoal(state, action) {
      state.answers.goal = parseInt(action.payload);
      state.questionIndex += 1;
    },
    addAttitude(state, action) {
      state.answers.attitude = parseInt(action.payload);
      state.questionIndex += 1;
    },
    addRisk(state, action) {
      state.answers.risk = parseInt(action.payload);
      state.questionIndex += 1;
    },
    addExperience(state, action) {
      state.answers.experience = parseInt(action.payload);
      state.questionIndex += 1;
    },
    addWealth(state, action) {
      state.answers.wealth = parseInt(action.payload);
      state.questionIndex += 1;
    },
    addStability(state, action) {
      state.answers.stability = parseInt(action.payload);
      state.questionIndex += 1;
    },
    addFinanbestAnswerExpenses(state,action){
      state.answers_finanbest.expenses=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer1(state,action){
      state.answers_finanbest.income=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer3(state,action){
      state.answers_finanbest.stability=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer4(state,action){
      state.answers_finanbest.wealth=parseInt(action.payload)
      state.questionIndex+=1
    },
    addFinanbestAnswer5(state,action){
      state.answers_finanbest.investment=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer6(state,action){
      state.answers_finanbest.years_investing=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer7(state,action){
      state.answers_finanbest.studies=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer8(state,action){
      state.answers_finanbest.experience=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer10(state,action){
      state.answers_finanbest.goal=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer12(state,action){
      state.answers_finanbest.risk=action.payload
      state.questionIndex+=1
    },
    addFinanbestAnswer13(state,action){
      state.answers_finanbest.attitude=action.payload
      state.questionIndex+=1
    },
    prevQuestion(state, action) {
      state.questionIndex -= 1;
    },
    nextQuestion(state, action) {
      state.questionIndex += 1;
    },
    setIsTestDialogVisible(state,action){
      state.isTestDialogVisible=action.payload
      if(action.payload===false){
        state.questionIndex=0
      }
    },
    setIsRiskProfileDrawerVisible(state,action){
      state.isRiskProfileDrawerVisible=action.payload
    },
    setRiskProfileError(state,action){
      state.error=action.payload
    }
  },
  extraReducers: {
    [sendRiskProfileForm.pending]: (state) => {
      state.loading = true;
    },
    [sendRiskProfileForm.fulfilled]: (state, action) => {
      state.data = action.payload["IndexaCapital/risk_profile"];
      state.loading = false;
    },
    [sendRiskProfileForm.rejected]: (state) => {
      state.loading = false;
    },
    [sendRiskProfileFormFinanbest.pending]: (state) => {
      state.loading = true;
      state.isTestDialogVisible=false
      state.isRiskProfileDrawerVisible=false
    },
    [sendRiskProfileFormFinanbest.fulfilled]: (state, action) => {
      window.dataLayer.push({
        'event':'setRiskProfile',
        'risk_profile':action.payload["finanbest/risk_profile"].profile_id
      })
      return {...state,loading:false,data:{profile:action.payload["finanbest/risk_profile"].profile_id}}
    },
    [sendRiskProfileFormFinanbest.rejected]: (state) => {
      state.loading = false;
      state.questionIndex-=1
    },
    [getRiskProfiles.pending]: (state,action)=>{
      state.loading=true
    },
    [getRiskProfiles.fulfilled]: (state,action)=>{
      return {...state,loading:false, riskProfiles:action.payload, isTestDialogVisible:false, error:false}
    },
    [getRiskProfiles.rejected]: (state,action)=>{
      state.loading=false
      state.error=true
    },
    [getRiskProfileData.pending]: (state,action)=>{
      state.loading=true
    },
    [getRiskProfileData.fulfilled]: (state,action)=>{
      return {
        ...state,
        loading:false,
        data:{
          ...state.data,
          maxProfile:action.payload.reduce((profileNumber,profile)=>{
            if(profile.main===true){
              profileNumber=parseInt(profile.identifier)
            }
            return profileNumber
          },0),
          profile:action.payload.reduce((profileNumber,profile)=>{
            if(profile.is_active===true){
              profileNumber=parseInt(profile.identifier)
            }
            return profileNumber
          },0)
        }}
    },
    [getRiskProfileData.rejected]: (state,action)=>{
      state.loading=false
    },
    [selectNewRiskProfile.pending]: (state,action)=>{
      state.loading=true
    },
    [selectNewRiskProfile.fulfilled]: (state,action)=>{
      state.loading=false
    },
    [selectNewRiskProfile.rejected]: (state,action)=>{
      state.loading=false
    },
    [getPerformanceActiveRiskProfile.pending]: (state,action)=>{
      state.loading=true
    },
    [getPerformanceActiveRiskProfile.fulfilled]: (state,action)=>{
      state.loading=false
      state.data.min_performance=action.payload.min_performance
      state.data.max_performance=action.payload.max_performance
      state.data.expected_performance=action.payload.expected_performance
    },
    [getPerformanceActiveRiskProfile.rejected]: (state,action)=>{
      state.loading=false
    }
  },
});

export const {
  addRiskProfile,
  addAttitude,
  addExperience,
  addGoal,
  addRisk,
  addStability,
  addWealth,
  prevQuestion,
    addFinanbestRiskProfile,
    addFinanbestAnswer1,
    addFinanbestAnswer3,
    addFinanbestAnswer4,
    addFinanbestAnswer5,
    addFinanbestAnswer6,
    addFinanbestAnswer7,
    addFinanbestAnswer8,
    addFinanbestAnswer10,
    addFinanbestAnswer12,
    addFinanbestAnswer13,
    addFinanbestAnswerExpenses,
    beginRiskProfileTest,
    setIsTestDialogVisible,
    setIsRiskProfileDrawerVisible,
    setRiskProfileError
} = riskProfileSlice.actions;
export const selectRiskProfile = (state) => state.risk_profile;
export default riskProfileSlice.reducer;
