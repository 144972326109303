import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getRecommendation, getRecommendationOfActivePlanById} from "../../services/recommendation/recommendation";
import {postFinanbestLogin, postFinanbestUser} from "../../services/contract/user/user";
import {postFinanbestDni, postFinanbestKyc} from "../../services/contract/kyc/kyc";
import {getFinanbestContracts} from "../../services/contract/contract/contract";

const initialState={
    data:null,
    loading:false,
    contract:{
        user:null,
        kyc:null,
        services:null,
        saml:null,
        route:'/contratacion',
        error:false
    }
}

export const getRecommendationActivePlanObjective=createAsyncThunk(
    'recommendation/getRecommendationActivePlanObjective',
    async (data,thunkApi)=>{
        return getRecommendationOfActivePlanById(data).then(response=>response.data)
    }
)

export const postUserRegistration=createAsyncThunk(
    'recommendation/postUserRegistration',
    async (data,thunkApi)=>{
        return postFinanbestUser(data).then(userResponse=>{
            return postFinanbestLogin({password:data.password,username:data.email}).then(loginResponse=>userResponse.data)
        })
    }
)

export const postKyc=createAsyncThunk(
    'recommendation/postFinanbestKyc',
    async (data,thunkApi)=>{
        return postFinanbestDni(data.formData).then(response=>postFinanbestKyc(data.kyc).then(responseKyc=>responseKyc))

    }
)

export const getContracts=createAsyncThunk(
    'recommendation/getContract',
    async (data,thunkApi)=>{
        const recommendation=thunkApi.getState().recommendation
        const mutualContracts=recommendation.contract.services?.mutual?recommendation.contract.services?.mutual:[]
        //const pensionContracts=recommendation.contract.services?.pension?recommendation.contract.services?.pension:[]
        //const depositContracts=recommendation.contract.services?.deposit?recommendation.contract.services?.deposit:[]

        const allContracts=mutualContracts
        const samlResponses=allContracts.map((service)=>{
            return getFinanbestContracts(service.product.profile_id)
                .then(response=>{ return {profile_id:service.product.profile_id,saml:response}})
        })
        return Promise.all(samlResponses)
            .then(response=>response)

    }
)

export const getPlanRecommendation = createAsyncThunk(
    'recommendation/getPlanRecommendation',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (planId,thunkAPI)=>{
        const productsResponse= getRecommendation({planId:planId,with_financial_service:false}).then(response=>{
                return response.data
            }
        )
        const contractServicesResponse=getRecommendation({planId:planId,with_financial_service:true}).then(response=>{
                return response.data
            }
        )
        return Promise.all([productsResponse,contractServicesResponse]).then(responsesData=>{

            /*
                we return a number of objects equal to the number of petitions with the name of the url we are calling
                because we name in the initial state our parameters as the names on the endpoints
             */
            return responsesData.reduce((obj,responseData,index)=>{

                obj[index]=responseData
                return obj
            },{})
        })

    }
)

const recommendationSlice=createSlice({
    name:"recommendation",
    initialState:initialState,
    reducers:{
        setContractRoute(state,action){
            return{...state,contract:{...state.contract,route:action.payload}}
        },
        setRecommendationError(state,action){
            state.error=action.payload
        },
    },
    extraReducers:{
        [getPlanRecommendation.pending]:(state)=>{
            state.loading=true
        },
        [getPlanRecommendation.fulfilled]:(state,action)=>{
            return {...initialState, data:action.payload[0],contract:{...initialState.contract,services: action.payload[1]}, loading:false,error:false}
        },
        [getPlanRecommendation.rejected]:(state)=>{
            state.loading=false
            state.error=true
        },
        [getRecommendationActivePlanObjective.pending]:(state)=>{
            state.loading=true
        },
        [getRecommendationActivePlanObjective.fulfilled]:(state,action)=>{
            return {...initialState, data:[action.payload],contract:{...initialState.contract,services: action.payload[1]}, loading:false,error:false}
        },
        [getRecommendationActivePlanObjective.rejected]:(state)=>{
            state.loading=false
            state.error=true
        },
        [postUserRegistration.pending]:(state)=>{
            state.loading=true
        },
        [postUserRegistration.fulfilled]:(state,action)=>{
            return {...state, contract: {...state.contract,user:action.payload,route:'/contratacion/kyc'},loading:false}
        },
        [postUserRegistration.rejected]:(state)=>{
            state.loading=false
        },
        [postKyc.pending]:(state)=>{
            state.loading=true
        },
        [postKyc.fulfilled]:(state,action)=>{
            state.loading=false
            state.contract.route='/contratacion/contratos'
        },
        [postKyc.rejected]:(state)=>{
            state.loading=false
        },
        [getContracts.pending]:(state)=>{
            state.loading=true
        },
        [getContracts.fulfilled]:(state,action)=>{
            state.contract.saml=action.payload
            state.loading=false
        },
        [getContracts.rejected]:(state)=>{
            state.loading=false
        },


    }
})
export const {
    setContractRoute,
    setRecommendationError
}=recommendationSlice.actions
export const selectRecommendation=(state)=>state.recommendation
export default recommendationSlice.reducer